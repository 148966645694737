<script setup lang="ts">
import { computed, ref, watchEffect, type Ref } from 'vue'
import { useAuth } from '@/composables/useAuth'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n/index.js'
import TabPanel from 'primevue/tabpanel'
import TabView from 'primevue/tabview'
import RestaurantTab from '@/components/Profile/MyProfile/RestaurantTab.vue'
import ProfileTab from '@/components/Profile/MyProfile/ProfileTab.vue'
import { useFetch } from '@/composables/useFetch.js'
import { useEnvStore } from '@/stores/envStore.js'
import type { AttributesList, Restaurant } from '@/types/restaurants.js'
import ToolingView from '@/components/Profile/MyProfile/ToolingView.vue'
import { useRoute, useRouter } from 'vue-router'
import LoadingView from '../LoadingView.vue'
import { useToast } from 'primevue/usetoast'
import AddImageIcon from '@/assets/icons/Planning/AddImageIcon.vue'
import ImagePlaceholderIcon from '@/assets/icons/Profile/ImagePlaceholderIcon.vue'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const { fetchData } = useFetch()
const router = useRouter()
const route = useRoute()

const toast = useToast()

const { user } = useAuth()
const restaurant: Ref<Restaurant | null> = ref(null)
const attributes: Ref<AttributesList | null> = ref(null)
const toolingViewDisplayed = computed(() => route.query.showTooling === 'true')

const fileInput: Ref<HTMLInputElement | null> = ref(null)

watchEffect(async () => {
  if (user.value?.restaurants[0].id && !restaurant.value) {
    const { data: dbRestaurant, error: dbRestaurantError } = await fetchData(
      useEnvStore().apiUrl + '/restaurants/' + user.value?.restaurants[0].id,
      'GET'
    )
    if (dbRestaurantError) console.log(dbRestaurantError)
    else restaurant.value = dbRestaurant

    const { data: dbAttributes, error: dbAttributesError } = await fetchData(
      useEnvStore().apiUrl + '/restaurants/attributes',
      'GET'
    )
    if (dbAttributesError) console.log(dbAttributesError)
    else attributes.value = dbAttributes
  }
})

const userInfos = computed(() => {
  if (user.value) {
    return {
      fullName: `${user.value.first_name} ${user.value.last_name}`,
      restaurantName: user.value.restaurants[0].name,
      restaurationType: user.value.restaurants[0].restauration_type,
    }
  }
  return {
    fullName: '',
    restaurantName: '',
    restaurationType: '',
  }
})

async function uploadRestaurantLogo(event: any) {
  const files = event.target.files
  if (files.length > 0) {
    const file: File = files[0]

    // Taille maximale autorisée (en octets) - ici 2 Mo (2048 Ko)
    const maxFileSize = 2 * 1024 * 1024

    // Vérifier la taille de l'image avant de procéder à l'upload
    if (file.size > maxFileSize) {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('menuDishView.fileTooLarge', { maxSizeMB: '2' }),
        life: 3000,
      })
      return // Empêcher l'upload si le fichier est trop gros
    }

    const formData = new FormData()
    formData.append('logo', file)

    try {
      const { data, error } = await fetchData(
        `${useEnvStore().apiUrl}/restaurants/${restaurant.value?.id}/logo`,
        'POST',
        formData
      )

      // Si une erreur est retournée, vérifiez et affichez un message spécifique ou générique
      if (error) {
        console.log(error)
        const errorMessage =
          error?.details?.errors?.logo?.[0] || t('menuDishView.addUserImageError')

        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: errorMessage,
          life: 3000,
        })
      } else if (data && restaurant.value) {
        // Si l'opération est réussie, mettez à jour le logo du restaurant
        restaurant.value.logo_url = data.logo_url
        restaurant.value.logo = data.logo

        toast.add({
          severity: 'success',
          summary: t('common.success'),
          detail: t('menuDishView.addUserImageSuccess'),
          life: 3000,
        })
      }
    } catch (error) {
      console.error('An unexpected error occurred:', error)
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('menuDishView.addUserImageError'),
        life: 3000,
      })
    }
  }
}
</script>

<template>
  <main>
    <div class="flex flex-row items-center gap-4 mb-6 justify-items-start">
      <div class="flex justify-center">
        <!-- Le div contenant le cercle -->
        <div
          @click="fileInput?.click()"
          class="relative flex items-center justify-center object-cover w-24 h-24 p-1 border-2 rounded-full cursor-pointer aspect-square max-w-24 max-h-24 border-primary-200"
        >
          <!-- Affiche l'image si disponible -->
          <img
            v-if="restaurant?.logo"
            :src="restaurant?.logo"
            alt="Profile picture"
            class="absolute inset-0 object-cover w-full h-full p-1 rounded-full"
          />

          <!-- Affiche l'icône de placeholder si pas d'image -->
          <ImagePlaceholderIcon v-else class="w-8 h-8" />
        </div>

        <!-- Input pour télécharger une image -->
        <input
          ref="fileInput"
          type="file"
          name="dish-image"
          id="dish-image"
          accept="image/jpeg, image/jpg, image/png, image/webp, image/heic"
          @change="uploadRestaurantLogo"
          class="hidden mb-4"
        />
      </div>
      <div class="">
        <h1 class="mb-2">{{ userInfos.restaurantName }}</h1>
        <p class="font-titles">{{ userInfos.fullName }}</p>
        <p class="text-sm font-titles">{{ t(`restaurationType.${userInfos.restaurationType}`) }}</p>
      </div>
    </div>
    <div>
      <LoadingView v-if="!user || !attributes || !restaurant" />
      <TabView
        :pt="{ inkbar: { class: 'hidden' } }"
        :pt-options="{ mergeProps: true }"
        v-else-if="!toolingViewDisplayed"
      >
        <TabPanel
          header="Restaurant"
          :pt="{
            header: { class: '!w-1/2' },
            headerAction: { class: '!justify-center' },
            content: { class: '!px-0' },
          }"
          :pt-options="{ mergeProps: true }"
        >
          <RestaurantTab
            v-if="restaurant && attributes && user"
            :restaurant
            :attributes
            @update-restaurant="
              (updatedRestaurant) => {
                if (user) {
                  restaurant = updatedRestaurant
                  user.restaurants[0].name = restaurant.name
                  user.restaurants[0].restauration_type =
                    restaurant.attributes.restaurationType[0].name
                }
              }
            "
            @go-to-tooling="
              () => {
                router.push({ name: 'my-profile', query: { showTooling: 'true' } })
              }
            "
          />
        </TabPanel>
        <TabPanel
          header="Profil"
          :pt="{
            header: { class: '!w-1/2' },
            headerAction: { class: '!justify-center' },
            content: { class: '!px-0' },
          }"
          :pt-options="{ mergeProps: true }"
        >
          <ProfileTab v-if="user" :user @update-user="user = $event" />
        </TabPanel>
      </TabView>
      <ToolingView
        v-else-if="toolingViewDisplayed && attributes && restaurant"
        :attributes
        :restaurant
        @update-restaurant="
          (updatedRestaurant) => {
            restaurant = updatedRestaurant
          }
        "
      />
    </div>
  </main>
</template>
