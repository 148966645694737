<!-- DayMenuView.vue -->
<script setup lang="ts">
import { onMounted, ref, watch, type Ref } from 'vue';
import MenuView from './MenuView.vue';
import { usePlanningStore } from '@/stores/planningStore';
import type { PlannedMenu } from '@/types/planning';
import { useAuth } from '@/composables/useAuth';
import { useI18n } from 'vue-i18n';
import type { MessageSchema } from '@/i18n';
import { useRouter } from 'vue-router';
const user = ref(useAuth().user)
const restaurantId = user.value?.restaurants[0]?.id || null


// Define props for the component
const props = defineProps<{
  date: Date
}>();
const planningStore = ref(usePlanningStore());
const plannedMenusForDay: Ref<PlannedMenu[]> = ref([]);
const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const router = useRouter()


async function fetchMenuForDay() {
  const formattedDate = props.date.toISOString().split('T')[0];
  plannedMenusForDay.value = planningStore.value.getMenusByDate(formattedDate);
}
// Define a list of dishes for the current day
const dishes = ref<string[]>([]);

const isScrollable = ref(false);
const containerRef = ref<HTMLDivElement | null>(null);
const showLeftShadow = ref(false);
const showRightShadow = ref(false);

function handleScroll() {
  if (!containerRef.value) return;
  const scrollLeft = containerRef.value.scrollLeft;
  const maxScrollLeft = containerRef.value.scrollWidth - containerRef.value.clientWidth;

  showLeftShadow.value = scrollLeft > 0;
  showRightShadow.value = scrollLeft < maxScrollLeft;
}

onMounted(() => {
  fetchMenuForDay();
  if (containerRef.value) {
    containerRef.value.addEventListener('scroll', handleScroll);
  }
});


// Add a new dish to the list
function addPlannedMenu() {
  const newMenu: PlannedMenu = {
    id: -1,  // Placeholder ID, will be replaced by actual ID on server response
    tempId: Date.now(),  // Temporary ID for local use
    name: t('planning.menuOfTheDay'),
    date: props.date.toISOString().split('T')[0],
    price: 0,
    spec: '',
    restaurant_id: 'default_restaurant_id',
    menu_courses: []
  };

  planningStore.value.addPlannedMenu(newMenu, restaurantId, router);
}

function isToday(date: Date): boolean {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

watch(() => planningStore.value.plannedMenus, () => {
  fetchMenuForDay();
}, { deep: true });

watch(() => props.date, () => {
  fetchMenuForDay();
});

</script>

<template>
  <!-- <h3 class="font-semibold">{{ date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' }) }}</h3> -->
  <div class="flex items-center my-4 flex-row w-full">
    <!-- Left side for the day and number -->
    <div :class="[
      'text-center',
      'p-2',
      'rounded-md',
      'flex',
      'flex-col',
      'justify-center',
      'w-1/12',
      'min-w-12',
      'mx-4',
      isToday(props.date) ? 'bg-accent text-white' : 'text-primary'
    ]">
      <span class="text-sm">{{ t(`common.dayShort.${props.date.toLocaleDateString('en-US', { weekday: 'short' })}`) }}</span>
      <span class="text-lg font-bold">{{ props.date.getDate() }}</span>
    </div>

    <!-- Outer container for scrollable content with shadows -->
    <div class="scroll-container relative flex-grow w-9/12">
      <div ref="containerRef" class="overflow-x-auto hide-scrollbar flex items-center p" @scroll="handleScroll">
        <div class="flex flex-row gap-4">
          <div v-for="menu in plannedMenusForDay" :key="menu.id">
            <MenuView :date="props.date" :planned-menu="menu"></MenuView>
            <!-- other menu details -->
          </div>
          <!-- <MenuView :date="props.date"></MenuView>
          <MenuView :date="props.date"></MenuView>
          <MenuView :date="props.date"></MenuView> -->
          <!-- Add more MenuView components here -->
        </div>

        <!-- "Plus" button to add a new dish -->
        <div class="mx-6">
          <button @click="addPlannedMenu"
            class="p-2 bg-accent text-white rounded-full aspect-square w-10 flex justify-center items-center">
            <!-- Icon plus -->
            <i class="pi pi-plus" style="font-size: 1rem"></i>
          </button>
        </div>
      </div>

      <!-- Shadows for overflow on the left and right -->
      <div v-if="showLeftShadow" class="left-shadow"></div>
      <div v-if="showRightShadow" class="right-shadow"></div>
    </div>
  </div>
</template>


<style scoped>
/* Hide the scroll bar but still allow horizontal scrolling */
.hide-scrollbar {
  overflow-x: auto;
  scrollbar-width: none;
  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera */
}

/* Outer container to hold the scrollable area and the shadows */
/* .scroll-container {
  position: relative;
  max-width: 100%;
  width: 100%;
} */

/* Inner shadow for overflow */
.left-shadow,
.right-shadow {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 6px; /*10*/
  pointer-events: none;
  /* z-index: 0; */
}

.left-shadow {
  left: 0;
  /* background: linear-gradient(to right, rgba(255, 255, 255, 1), transparent); */
  background: linear-gradient(to right, rgb(193 214 224), rgba(193, 214, 224, 0))
}

.right-shadow {
  right: 0;
  /* background: linear-gradient(to left, rgba(255, 255, 255, 1), transparent); */
  background: linear-gradient(to left, rgb(193 214 224), rgba(193, 214, 224, 0));
}
</style>
