<script setup lang="ts">
import HistoryIcon from '@/assets/icons/Profile/HistoryIcon.vue'
import { markRaw } from 'vue'
import HeartEmpty from '@/assets/icons/2States/HeartEmpty.vue'
import ScanIcon from '@/assets/icons/Profile/ScanIcon.vue'
// import { useI18n } from 'vue-i18n'
// import type { MessageSchema } from '@/i18n/index.js'
import MyRecipesMenuItem from '@/components/MyRecipes/MyRecipesMenuItem.vue'
import PlanningIcon from '@/assets/icons/Planning/PlanningIcon.vue'

// const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const links = [
  {
    name: 'Historique',
    icon: markRaw(HistoryIcon),
    url: '/my-recipes/generation-history',
  },
  {
    name: 'Menus favoris',
    icon: markRaw(HeartEmpty),
    url: '/my-recipes/favorites',
  },
  {
    name: 'Mes scans',
    icon: markRaw(ScanIcon),
    url: '/my-recipes/my-scans',
  },
  {
    name: 'Planifiés',
    icon: markRaw(PlanningIcon),
    url: '/my-recipes/planned',
  },
]
</script>

<template>
  <main>
    <div class="mt-4">
    <MyRecipesMenuItem v-for="(link, index) in links" :key="link.name" :name="link.name" :icon="link.icon" :url="link.url"
      :index="index" />
      </div>
  </main>
</template>
