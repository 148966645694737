import { ref } from 'vue'
import { AxiosError } from 'axios'
import axios from '@/utils/axios'

const isFetching = ref(false)

export function useFetch() {
  async function fetchData(
    url: string,
    method: 'GET' | 'DELETE' | 'POST' | 'PUT' | 'PATCH' = 'GET',
    body?: Object | FormData,
    timeoutParam: number = 60000
  ) {
    let data: any = null
    let error: any = null

    isFetching.value = true

    try {
      if ((method === 'POST' || method === 'PUT') && !body) {
        throw new Error('Missing body')
      }

      const isFormData = body instanceof FormData

      const options = {
        method,
        headers: {
          Accept: 'application/json',
          ...(isFormData
            ? { 'Content-Type': 'multipart/form-data' }
            : { 'Content-Type': 'application/json' }),
        },
        data: isFormData ? body : JSON.stringify(body),
        timeout: timeoutParam,
      }

      const response = await axios(url, options)

      data = response.data
    } catch (err) {
      const axiosError = err as AxiosError
      error = {
        code: axiosError.response ? 'ERR_BAD_RESPONSE' : 'ERR_ABORTED',
        details: axiosError.response ? axiosError.response.data : axiosError,
        message: axiosError.response ? 'Bad response from server' : 'Failed to fetch',
        path: url,
        timestamp: new Date(),
        status: axiosError.response ? axiosError.response.status : 500,
      }
    } finally {
      isFetching.value = false
    }
    return { data, error }
  }

  return { fetchData, isFetching }
}
