<script setup lang="ts">
import { RouterView, useRoute } from 'vue-router'
import TheNavBar from '@/components/NavBar/TheNavBar.vue'
import TheHeader from './components/Header/TheHeader.vue'
import Toast from 'primevue/toast'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { watchEffect } from 'vue'
import ConfirmPopup from 'primevue/confirmpopup'
import { useAppStore } from './stores/appStore.js'
import BaseLoader from '@/components/BaseComponents/BaseLoader.vue'

const { locale } = useI18n()

const isAppReady = computed(() => useAppStore().appReady)

watchEffect(() => {
  console.log(locale.value)
  console.log(isAppReady.value)
})

const route = ref(useRoute())

const containerClass = computed(() => ({
  'mb-4': route.value.meta.footerConfig?.hide,
  'mb-20': !route.value.meta.footerConfig?.hide,
  'container': route.value.meta.containerMargins, // Apply 'container' class conditionally
}))
</script>

<template>
  <Toast />
  <ConfirmPopup />
  <div class="max-w-[40rem] mx-auto">
    <TheHeader v-if="!route.meta.headerConfig?.hide && isAppReady" />
    <div class="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" v-if="!isAppReady">
      <BaseLoader />
    </div>
    <div v-else :class="containerClass">
      <RouterView />
    </div>
    <TheNavBar v-if="!route.meta.footerConfig?.hide && isAppReady" />
  </div>
</template>
