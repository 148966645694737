<script setup lang="ts">
import { useCategories } from '@/composables/useCategories'
import { useFetch } from '@/composables/useFetch'
import type { MessageSchema } from '@/i18n'
import { useDishesStore } from '@/stores/dishesStore'
import { useEnvStore } from '@/stores/envStore'
import { type Ingredient } from '@/types/dish'
import { debounce } from '@/utils/functions'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import InputNumber from '@/components/BaseComponents/InputNumber.vue'
import InputText from 'primevue/inputtext'
import Sidebar from 'primevue/sidebar'
import {
  computed,
  onMounted,
  onUpdated,
  ref,
  watch,
  watchEffect,
  type ModelRef,
  type Ref,
} from 'vue'
import { useI18n } from 'vue-i18n'

const { t, te } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const { categories } = useCategories()
const showSideBar: ModelRef<boolean | undefined> = defineModel('visible', { required: true })
const sideBarIngredient: ModelRef<Ingredient | undefined> = defineModel('ingredient')
const ingredientCategory = { name: t('orders.ingredientsCategories.other'), value: 'other' }
const dishesStore = useDishesStore()
const ingredientUnits = ref(dishesStore.units)
const { isFetching, fetchData } = useFetch()

const edition = ref(false)

const emit = defineEmits({
  'ingredient-edited': null,
  'delete-ingredient': (index: number) => true,
  'edit-ingredient': null,
  'add-ingredient': null,
})
// const ingredient = ref(props.ingredient);

const props = withDefaults(
  defineProps<{
    // ingredients: Ingredient[]
    showPrice?: boolean
  }>(),
  {
    showPrice: false,
  }
)
onUpdated(() => {
  edition.value = sideBarIngredient.value?.id === -1 ? false : true
})

onMounted(async () => {
  if (!ingredientUnits.value.length) {
    const { data, error } = await fetchData(useEnvStore().apiUrl + `/units/default`, 'GET')
    if (error) {
      console.log('error', error)
    } else {
      ingredientUnits.value = data
    }
  }
})
const sideBarIngredientIndex = ref(0)
console.log('Side bar ingredient : ', sideBarIngredient.value)

watchEffect(() => {
  console.log('SIDEBAR INGREIDNET', sideBarIngredient.value?.category)
})

// watch(() => sideBarIngredient.value?.quantity, (newQuantity, oldQuantity) => {
//   if (sideBarIngredient.value && oldQuantity !== undefined && newQuantity !== undefined && sideBarIngredient.value.price) {
//     const oldPrice = sideBarIngredient.value.price;
//     const proportion = newQuantity / oldQuantity;
//     sideBarIngredient.value.price = oldPrice * proportion;
//   }
// });
// console.log("Side basr")
// console.log(sideBarIngredient.value)
// watch(sideBarIngredientIndex, (currentIngredient) => {
//   if (currentIngredient) {
//     sideBarIngredient.value = currentIngredient;
//   }
// });

function deleteIngredient() {
  // console.log("sidebar ingredient")
  // console.log(sideBarIngredient.value)
  // console.log("sideBar index", sideBarIngredient.value)
  if (sideBarIngredient.value && typeof sideBarIngredientIndex.value === 'number') {
    emit('delete-ingredient', sideBarIngredientIndex.value)
  }
  resetSideBarIngredient()
  showSideBar.value = false
}

function addIngredient() {
  emit('add-ingredient')
  resetSideBarIngredient()
  showSideBar.value = false
}

function editIngredient() {
  emit('edit-ingredient')
  resetSideBarIngredient()
  showSideBar.value = false
}

function resetSideBarIngredient() {
  sideBarIngredient.value = {
    id: -1,
    category: categories.value[0].value,
    name: '',
    quantity: 0,
    unit: '',
    price: 0,
  }
}

watch(
  () => sideBarIngredient.value,
  (newSideBarIngredient: Ingredient | undefined) => {
    console.log('DBOUT')
    if (!sideBarIngredient.value || !newSideBarIngredient) return
    if (!newSideBarIngredient.name) {
      sideBarIngredient.value.name = ''
    }
    if (newSideBarIngredient.quantity === undefined || newSideBarIngredient.quantity === null) {
      sideBarIngredient.value.quantity = 0
    }
    if (!newSideBarIngredient.unit === undefined || newSideBarIngredient.unit === null) {
      sideBarIngredient.value.unit = ''
    }
    if (!newSideBarIngredient.category) {
      sideBarIngredient.value.category = 'other'
    }
    if (newSideBarIngredient.price === undefined || newSideBarIngredient.price === null) {
      sideBarIngredient.value.price = 0
    }
  },
  { deep: true }
)

const isNameInvalid = computed(
  () =>
    sideBarIngredient.value?.name === undefined ||
    sideBarIngredient.value?.name === null ||
    sideBarIngredient.value?.name === ''
)
const isQuantityInvalid = computed(() => {
  console.log(sideBarIngredient.value?.quantity)
  return (
    sideBarIngredient.value?.quantity === undefined || sideBarIngredient.value?.quantity === null
  )
})
const isUnitInvalid = computed(
  () => sideBarIngredient.value?.unit === undefined || sideBarIngredient.value?.unit === null
)
const isCategoryInvalid = computed(
  () =>
    sideBarIngredient.value?.category === undefined || sideBarIngredient.value?.category === null
)

const isFormInvalid = computed(
  () =>
    isNameInvalid.value || isQuantityInvalid.value || isUnitInvalid.value || isCategoryInvalid.value
)
</script>
<template>
  <Sidebar
    v-model:visible="showSideBar"
    v-if="sideBarIngredient"
    :header="`${edition ? t('profileFavorites.creationForm.editIngredientButton') : t('profileFavorites.creationForm.addIngredientButton')}`"
    position="bottom"
    style="height: auto"
    :block-scroll="true"
  >
    <div class="flex flex-col gap-4">
      <div class="flex flex-col">
        <label>{{ t('profileFavorites.creationForm.name') }}</label>
        <InputText
          v-model="sideBarIngredient.name"
          :placeholder="t('profileFavorites.creationForm.namePlaceholder')"
          :invalid="isNameInvalid"
        />
      </div>
      <div class="grid grid-cols-2 gap-4">
        <div class="flex flex-col">
          <label>{{ t('profileFavorites.creationForm.quantityLong') }}</label>
          <InputNumber
            v-model="sideBarIngredient.quantity"
            :placeholder="t('profileFavorites.creationForm.quantityPlaceholder')"
            :step="0.01"
            :invalid="isQuantityInvalid"
          />
          <!-- <InputNumber
            v-model="sideBarIngredient.quantity"
            :placeholder="t('profileFavorites.creationForm.quantityPlaceholder')"
            :min-fraction-digits="2"
            :invalid="isQuantityInvalid"
          /> -->
        </div>
        <div class="flex flex-col">
          <label>{{ t('profileFavorites.creationForm.unit') }}</label>
          <!-- <InputText v-model="sideBarIngredient.unit"
            :placeholder="t('profileFavorites.creationForm.unitPlaceholder')" /> -->

          <Dropdown
            v-model="sideBarIngredient.unit"
            :options="ingredientUnits"
            optionLabel="symbol"
            optionValue="symbol"
            :placeholder="t('profileFavorites.creationForm.unitPlaceholder')"
            class="w-full md:w-[14rem]"
            :invalid="isUnitInvalid"
          >
            <template #value="slotProps">
              <div class="flex items-center" v-if="slotProps.value">
                <div>
                  {{
                    te(`ingredients.unit.symbol.${slotProps.value}`)
                      ? t(`ingredients.unit.symbol.${slotProps.value}`)
                      : slotProps.value
                  }}
                </div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="flex items-center">
                <div>
                  {{
                    te(`ingredients.unit.symbol.${slotProps.option.symbol}`)
                      ? t(`ingredients.unit.symbol.${slotProps.option.symbol}`)
                      : slotProps.option.symbol
                  }}
                </div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="flex flex-col" v-if="props.showPrice">
          <label>{{ t('profileFavorites.creationForm.price') }}</label>
          <InputNumber
            v-model="sideBarIngredient.price"
            :placeholder="t('profileFavorites.creationForm.pricePlaceholder')"
            :step="0.01"
          />
        </div>
      </div>
      <Dropdown
        v-model="sideBarIngredient.category"
        :options="categories"
        optionLabel="name"
        optionValue="value"
        :placeholder="t('ingredients.category.label')"
        class="w-full md:w-[14rem]"
        :invalid="isCategoryInvalid"
      />
      <Button
        v-if="edition"
        :label="t('profileFavorites.creationForm.deleteIngredientButton')"
        @click="deleteIngredient()"
        severity="danger"
        text
      />
      <Button
        :label="
          edition ? t('profileFavorites.creationForm.edit') : t('profileFavorites.creationForm.add')
        "
        @click="edition ? editIngredient() : addIngredient()"
        :disabled="isFormInvalid"
      />
    </div>
  </Sidebar>
</template>
