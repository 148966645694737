<script setup lang="ts">
import type { Supplier } from '@/types/api.js'
import LocationIcon from '@/assets/icons/Other/LocationIcon.vue'
import HeartFull from '@/assets/icons/2States/HeartFull.vue'
import Button from 'primevue/button'
import HeartEmpty from '@/assets/icons/2States/HeartEmpty.vue'
import { useAuth } from '@/composables/useAuth.js'
import { useFetch } from '@/composables/useFetch.js'
import { useEnvStore } from '@/stores/envStore.js'

defineProps<{
  supplier: Supplier
  isFavorite: boolean
}>()

const emit = defineEmits<{
  (e: 'updateFavorite', add: boolean): void
}>()

const { user } = useAuth()
const { fetchData } = useFetch()

async function toggleFavorite(supplierUuid) {
  if (!user.value) return
  const restaurantId = user.value.restaurants[0].id
  fetchData(
    `${useEnvStore().apiUrl}/restaurants/${restaurantId}/suppliers/${supplierUuid}/toggle-favorite`,
    'PATCH'
  ).then((response) => {
    if (response.data) {
      emit('updateFavorite', response.data.status === 'added')
    } else {
      console.log(response.error)
    }
  })
}
</script>

<template>
  <div class="w-full p-3 space-y-2 border-2 shadow-md rounded-xl border-primary-300">
    <div class="flex justify-between items-top">
      <p class="text-xl font-bold text-primary-800">{{ supplier.name }}</p>
      <Button
        link
        rounded
        :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }"
        :pt-options="{ mergeProps: true }"
        @click="toggleFavorite(supplier.uuid)"
      >
        <template #icon>
          <HeartFull v-if="isFavorite" />
          <HeartEmpty v-else />
        </template>
      </Button>
    </div>

    <div class="flex items-end justify-between w-full gap-4">
      <div>
        <p>DESCRIPTION A AJOUTER DANS LA DB</p>
        <p class="flex gap-2 text-sm text-primary-800">
          <span class="block w-4 aspect-square"><LocationIcon /></span>
          <span> {{ supplier.address.city.postal_code }} {{ supplier.address.city.name }} </span>
        </p>
      </div>
      <div class="h-16 shrink-0">
        <img src="https://via.placeholder.com/250x150" class="w-full h-full" alt="" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
