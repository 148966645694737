<script setup lang="ts">
import SupplierCard from '@/components/Profile/Suppliers/SupplierCard.vue'
import { useAuth } from '@/composables/useAuth.js'
import { useFetch } from '@/composables/useFetch.js'
import type { MessageSchema } from '@/i18n/index.js'
import { useEnvStore } from '@/stores/envStore.js'
import type { Supplier } from '@/types/api.js'
import Button from 'primevue/button'
import Sidebar from 'primevue/sidebar'
import AddCustomSupplierModal from '@/components/Profile/Suppliers/AddCustomSupplierModal.vue'
import { useToast } from 'primevue/usetoast'
import { computed, onMounted, ref, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'

const toast = useToast()
const envStore = useEnvStore()
const { user } = useAuth()
const { fetchData } = useFetch()
const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const addModalVisible = ref(false)
const restaurantUuid: Ref<string | null> = ref(null)
const mySuppliers: Ref<Supplier[]> = ref([])
const allSuppliers: Ref<Supplier[]> = ref([])
const otherSuppliers = computed(() => {
  return allSuppliers.value.filter(
    (supplier) => !mySuppliers.value.some((mySupplier) => mySupplier.uuid === supplier.uuid)
  )
})

function toggleFavorite(action: 'add' | 'remove', supplierUuid: string) {
  if (action === 'add') {
    const foundSupplier = allSuppliers.value.find((supplier) => supplier.uuid === supplierUuid)
    if (!foundSupplier) return
    mySuppliers.value.push(foundSupplier)
  } else {
    mySuppliers.value = mySuppliers.value.filter((supplier) => supplier.uuid !== supplierUuid)
  }
}

onMounted(() => {
  fetchData(`${envStore.apiUrl}/suppliers`).then((response) => {
    if (response.data) {
      allSuppliers.value = response.data as Supplier[]
    } else if (response.error) {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('profile.suppliers.getSuppliersError'),
        life: 3000,
      })
    }
  })

  if (user.value) {
    restaurantUuid.value = user.value?.restaurants[0].id
    fetchData(`${envStore.apiUrl}/restaurants/${restaurantUuid.value}/suppliers`).then(
      (response) => {
        if (response.data) {
          mySuppliers.value = response.data as Supplier[]
        } else if (response.error) {
          toast.add({
            severity: 'error',
            summary: t('common.error'),
            detail: t('profile.suppliers.getSuppliersError'),
            life: 3000,
          })
        }
      }
    )
  }
})
</script>

<template>
  <main>
    <div>
      <div class="flex items-center justify-between mb-2">
        <h3>{{ t('profile.suppliers.mySuppliers') }}</h3>
        <Button icon="pi pi-plus" @click="addModalVisible = true" />
      </div>
      <div class="flex flex-col gap-2">
        <SupplierCard
          v-for="supplier in mySuppliers"
          :key="supplier.uuid"
          :supplier="supplier"
          :is-favorite="true"
          @update-favorite="toggleFavorite('remove', supplier.uuid)"
        />
      </div>
    </div>

    <div class="mt-10">
      <h3 class="mb-2">{{ t('profile.suppliers.otherSuppliers') }}</h3>
      <div class="flex flex-col gap-2">
        <SupplierCard
          v-for="supplier in otherSuppliers"
          :key="supplier.uuid"
          :supplier="supplier"
          :is-favorite="false"
          @update-favorite="toggleFavorite('add', supplier.uuid)"
        />
      </div>
    </div>

    <Sidebar
      v-model:visible="addModalVisible"
      :header="t('profile.suppliers.newCustomSupplier')"
      position="bottom"
      style="height: auto"
      v-if="restaurantUuid"
    >
      <AddCustomSupplierModal :restaurant-uuid="restaurantUuid" />
    </Sidebar>
  </main>
</template>
