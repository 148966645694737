<script setup lang="ts">
import ChevronLeftIcon from '@/assets/icons/Planning/ChevronLeftIcon.vue'
import ChevronRightIcon from '@/assets/icons/Planning/ChevronRightIcon.vue'
import { useAuth } from '@/composables/useAuth'
import { useCategories } from '@/composables/useCategories'
import type { MessageSchema } from '@/i18n'
import { usePlanningStore } from '@/stores/planningStore'
import { type Dish, type Ingredient } from '@/types/dish'
import FavoritesView from '@/views/MyRecipes/FavoritesView.vue'
import GenerationHistoryView from '@/views/MyRecipes/GenerationHistoryView.vue'
import Sidebar from 'primevue/sidebar'
import { computed, ref, watch, type ModelRef } from 'vue'
import { useI18n } from 'vue-i18n'

const { t, te } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

// const { categories } = useCategories();
const showSideBar: ModelRef<boolean | undefined> = defineModel('visible', { required: true })
const sideBarIngredient: ModelRef<Ingredient | undefined> = defineModel('ingredient')
// const ingredientCategory = { name: t('orders.ingredientsCategories.other'), value: 'other' }
// const dishesStore = useDishesStore();
// const ingredientUnits = ref(dishesStore.units);
// const { isFetching, fetchData } = useFetch()

const user = ref(useAuth().user)
const planningStore = usePlanningStore()

const edition = ref(false)
const dishName = computed(() => {
  return planningStore.dishToPlan?.clipboardDish.name ?? ''
})

const emit = defineEmits<{
  (e: 'addDishToPlan', dish: Dish): void
}>()

// const ingredient = ref(props.ingredient);

const props = defineProps<{
  // ingredients: Ingredient[]
  category: 'history' | 'favorites'
  plannedMenuName: string
}>()

watch(showSideBar, (newValue) => {
  if (!newValue) {
    planningStore.choosingDishToPlan = false
  }
})

function addDishToPlan(dish: Dish) {
  if (!dish) return
  emit('addDishToPlan', dish)
}

const dialogLabel = computed(() => {
  if (props.category === 'history') {
    return t('planning.plannedMenu.addDishFromHistory', { plannedMenuName: props.plannedMenuName })
  } else if (props.category === 'favorites') {
    return t('planning.plannedMenu.addDishFromFavorites', {
      plannedMenuName: props.plannedMenuName,
    })
  } else {
    return t('planning.plannedMenu.addDish', { plannedMenuName: props.plannedMenuName })
  }
})
</script>
<template>
  <Sidebar
    v-model:visible="showSideBar"
    :header="dialogLabel"
    position="bottom"
    style="height: 90%"
    :block-scroll="true"
  >
    <GenerationHistoryView v-if="category === 'history'" @add-dish-to-plan="addDishToPlan" />
    <FavoritesView v-if="category === 'favorites'" @add-dish-to-plan="addDishToPlan" />
  </Sidebar>
</template>
