<script setup lang="ts">
import { useFetch } from '@/composables/useFetch.js'
import type { MessageSchema } from '@/i18n/index.js'
import { useEnvStore } from '@/stores/envStore.js'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import { useToast } from 'primevue/usetoast'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  restaurantUuid: string
}>()

const toast = useToast()
const envStore = useEnvStore()
const { fetchData } = useFetch()
const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const name = ref('')
const email = ref('')

function newCustomSupplier() {
  console.log('newCustomSupplier')
}
</script>

<template>
  <form class="flex flex-col gap-2" @submit.prevent="newCustomSupplier">
    <InputText v-model="name" :placeholder="t('profile.suppliers.creationForm.name')" />
    <InputText v-model="email" :placeholder="t('profile.suppliers.creationForm.email')" />
    <Button :label="t('profile.suppliers.creationForm.addSupplier')" />
  </form>
</template>
