<script setup lang="ts">
import type { Dish } from '@/types/dish.js'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n/index.js'
import type { MenuCourse } from '@/types/planning'
import { useRoute, useRouter } from 'vue-router';
import { computed, markRaw, ref, watch, type Ref } from 'vue';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import HistoryIcon from '@/assets/icons/Profile/HistoryIcon.vue';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import { usePlanningStore } from '@/stores/planningStore';
import { useConfirm } from 'primevue/useconfirm';
import AddMenuCourseIcon from '@/assets/icons/Planning/AddMenuCourseIcon.vue';
import PlanDishFromDialog from './PlanDishFromDialog.vue';
import MenuCourseInfoDialog from './MenuCourseInfoDialog.vue';
import { useDishesStore } from '@/stores/dishesStore';

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const route = useRoute()
const router = useRouter()
const popupMenu = ref()
const openMenuCourseModal = ref(false);
const confirmDialog = useConfirm();

const planningStore = ref(usePlanningStore());
const showPlanDishFromDialog: Ref<boolean> = ref(false)
const dialogCategory: Ref<'history' | 'favorites'> = ref('history');

const dishesStore = ref(useDishesStore());

const showMenuCourseInfoDialog = ref(false);
// Function to temporarily add the clipboard dish
// function addTemporaryDish() {
//   if (!planningStore.dishToPlanClipboard) return;
//   const dish = planningStore.dishToPlanClipboard;
//   emit('addOrUpdateMenuCourse', { id: -1, name: dish.name, order: props.order, dish: dish, planned_menu_id: -1 });
// }

const props = defineProps<{
  order: 1 | 2 | 3,
  menuCourse: MenuCourse | null
}>()

const newMenuCourse = ref<MenuCourse>({
  id: -1,
  name: '',
  order: props.order,
  planned_menu_id: -1,
  dish: null
});

const emit = defineEmits<{
  (event: 'addOrUpdateMenuCourse', newMenuCourse: MenuCourse),
  (event: 'deleteMenuCourse', menuCourseId: number)
}>()

const label = computed(() => {
  switch (props.order) {
    case 1:
      return t('planning.plannedMenu.starter');
    case 2:
      return t('planning.plannedMenu.mainDish');
    case 3:
      return t('planning.plannedMenu.dessert');
    default:
      return '';
  }
});

const redirectToDishDetail = () => {
  if (!props.menuCourse?.dish) return;
  const dishId = props.menuCourse?.dish.id; // Assuming dish has an id property
  router.push(`/menu/dishes/${dishId}`);
}

const items = ref([
  {
    items: [
      {
        label: t('planning.plannedMenu.generateDish'),
        icon: 'pi pi-arrow-up-right',
        command: () =>
          router.push(`/menu/create`)

      },
      {
        label: t('planning.plannedMenu.history'),
        icon: 'pi pi-history',
        command: () =>
          planDishFromPlanning('history')
      },
      {
        label: t('planning.plannedMenu.favorites'),
        icon: 'pi pi-heart',
        command: () =>
          planDishFromPlanning('favorites')
      },
      // {
      //   label: t('planning.plannedMenu.favorites'),
      //   icon: 'pi pi-heart',
      //   command: () =>
      //     console.log("Hello world")

      // },
      {
        label: t('planning.plannedMenu.writeDish'),
        icon: 'pi pi-pencil',
        command: () => {
          if (props.menuCourse) {
            newMenuCourse.value = {
              id: props.menuCourse.id,
              name: props.menuCourse.name ?? null,
              order: props.order,
              dish: props.menuCourse.dish ?? null,
              planned_menu_id: props.menuCourse.planned_menu_id
            };
          } else {
            newMenuCourse.value = {
              id: -1,
              name: '',
              order: props.order,
              planned_menu_id: -1,
              dish: null
            };
          }
          openMenuCourseModal.value = true;
        }

      },
      // {
      //   label: t('planning.plannedMenu.history'),
      //   icon: 'pi pi-history',
      //   command: () =>
      //     console.log("Hello world")

      // },
    ],
  },
])


function togglePopUpMenu(event: MouseEvent) {
  popupMenu.value.toggle(event)
}

function addOrUpdateMenuCourse() {
  if (planningStore.value.dishToPlan) {
    newMenuCourse.value = {
      id: -1,
      name: planningStore.value.dishToPlan.clipboardDish.name,
      order: props.order,
      planned_menu_id: -1,
      dish: planningStore.value.dishToPlan.clipboardDish
    };
    console.log("MenuCourse add clipboard", newMenuCourse.value)
    // planningStore.value.dishToPlanClipboard = null;
  } else if (newMenuCourse.value.id === -1) {
    console.log("MenuCourse create", newMenuCourse.value)
  } else {
    console.log("MenuCourse update", newMenuCourse.value)
    // emit('addOrUpdateMenuCourse', newMenuCourse.value);
    // Handle update logic here
  }
  emit('addOrUpdateMenuCourse', newMenuCourse.value);
  openMenuCourseModal.value = false;
}

function menuCourseModal(menuCourse: MenuCourse | null) {
  if (menuCourse) {
    newMenuCourse.value = {
      id: menuCourse.id,
      name: menuCourse.name ?? null,
      order: props.order,
      dish: menuCourse.dish ?? null,
      planned_menu_id: menuCourse.planned_menu_id
    };
  } else {
    newMenuCourse.value = {
      id: -1,
      name: '',
      order: props.order,
      planned_menu_id: -1,
      dish: null
    };
  }
  openMenuCourseModal.value = !openMenuCourseModal.value;
}

function deleteMenuCourse() {
  if (!props.menuCourse) return;
  // console.log("MenuCourse delete", newMenuCourse.value)
  emit('deleteMenuCourse', props.menuCourse.id);
}

function handleClickOnMenuCourse() {

  if (!props.menuCourse || planningStore.value.dishToPlan) return;
  if (props.menuCourse.dish) {
    dishesStore.value.showingDishFromPlan = true;
    showMenuCourseInfoDialog.value = true;
  } else {
    menuCourseModal(props.menuCourse);
  }
}

const confirmDeleteMenuCourse = () => {
  confirmDialog.require({
    message: t('planning.plannedMenu.menuCourse.delete.message'),
    header: t('planning.plannedMenu.menuCourse.delete.title'),
    icon: 'pi pi-exclamation-triangle',
    rejectClass: 'p-button-secondary p-button-outlined',
    rejectLabel: t('planning.plannedMenu.menuCourse.delete.cancel'),
    acceptLabel: t('planning.plannedMenu.menuCourse.delete.confirm'),
    accept: () => {
      deleteMenuCourse();
    },
    reject: () => { }
  });
};
async function planDishFromPlanning(category: 'history' | 'favorites') {
  // if (!dish.value) return

  planningStore.value.choosingDishToPlan = true;
  dialogCategory.value = category
  showPlanDishFromDialog.value = true;

}

async function addDishToPlan(dish: Dish) {
  if (!dish) return;
  newMenuCourse.value = {
    id: -1,
    name: dish.name,
    order: props.order,
    planned_menu_id: -1,
    dish: dish
  };
  showPlanDishFromDialog.value = false;
  planningStore.value.choosingDishToPlan = false;
  addOrUpdateMenuCourse();
}

// watch(() => props.menuCourse, (newValue) => {
//   console.log("Changing menuCourse", newValue)
// });
</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="grid grid-cols-12 gap-2 items-center">
      <div class="col-span-3 min-w-4">
        <p>{{ label }}</p>
      </div>
      <!-- <div
        class="cursor-pointer col-span-9 relative bg-primary-200 py-6 px-4 rounded-xl shadow-lg min-w-52 flex items-center"
        @click="redirectToDishDetail">
        <p class="text-sm line-clamp-2">{{ props.menuCourse.dish.name }}</p>
      </div> -->
      <div v-if="menuCourse" class="col-span-9 grid grid-cols-9 gaps-2 items-center">
        <div
          class="cursor-pointer col-span-8 relative py-6 px-4 rounded-xl shadow-lg min-w-52 flex items-center bg-primary-200"
          @click="handleClickOnMenuCourse">
          <p class="text-sm line-clamp-2">{{ menuCourse.dish?.name ?? menuCourse.name }}</p>
        </div>
        <div v-if="!planningStore.dishToPlan" class="col-span-1 flex text-right p-2 cursor-pointer justify-end"
          @click="confirmDeleteMenuCourse"><i class="pi pi-times"></i></div>

      </div>
      <div v-else class="cursor-pointer col-span-7 py-6 px-4 flex items-center justify-center">
        <button @click="planningStore.dishToPlan ? addOrUpdateMenuCourse() : togglePopUpMenu($event)"
          class="p-2 bg-accent text-white rounded-full aspect-square w-10 flex justify-center items-center">
          <!-- Icon plus -->
          <AddMenuCourseIcon v-if="planningStore.dishToPlan" class="text-white" />
          <i v-else class="pi pi-plus" style="font-size: 1rem"></i>
        </button>
        <!-- <Button link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8 rotate-90' } }" :pt-options="{ mergeProps: true }"
          @click="togglePopUpMenu">
          <template #icon>
            <ButtonMenu />
          </template>
</Button> -->
        <Menu ref="popupMenu" id="overlay_menu" :model="items" :popup="true" :pt="{ submenuHeader: { class: '!p-0' } }"
          :pt-options="{ mergeProps: true }" />
      </div>
    </div>
  </div>

  <template>
    <div class="card flex justify-center">
      <Button label="Show" @click="openMenuCourseModal = true" />
      <Dialog v-model:visible="openMenuCourseModal" modal :header="t('planning.plannedMenu.addDishTitle')"
        :style="{ width: '25rem' }">
        <div v-if="newMenuCourse" class="flex items-center gap-4 mb-4">
          <label for="dishTitle" class="font-semibold w-24">{{ t('planning.plannedMenu.nameOfTheDish') }}</label>
          <InputText id="dishTitle" v-model="newMenuCourse.name" class="flex-auto" autocomplete="off" />
        </div>
        <div class="flex justify-end gap-2">
          <Button type="button" :label="t('common.cancel')" severity="secondary"
            @click="menuCourseModal(null)"></Button>
          <Button type="button" :label="t('common.save')" @click="addOrUpdateMenuCourse"></Button>
        </div>
      </Dialog>
    </div>
  </template>
  <PlanDishFromDialog v-model:visible="showPlanDishFromDialog"
    :planned-menu-name="planningStore.selectedPlannedMenu?.name ?? ''" :category="dialogCategory"
    @add-dish-to-plan="addDishToPlan" />
  <MenuCourseInfoDialog v-if="menuCourse?.dish" v-model:visible="showMenuCourseInfoDialog"
    :planned-menu-name="planningStore.selectedPlannedMenu?.name ?? ''" :menu-course="menuCourse"/>
</template>

<style scoped></style>
