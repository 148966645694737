<script setup lang="ts">
import type { Dish, plannedDishInfo } from '@/types/dish.js'
import BaseCard from '../BaseComponents/BaseCard.vue'
import { calculateDishPrice } from '@/utils/prices.js'
import ChefHatFull from '@/assets/icons/2States/ChefHatFull.vue'
import ChefHatEmpty from '@/assets/icons/2States/ChefHatEmpty.vue'
import IngredientsList from '../Menus/IngredientsList.vue'
import Button from 'primevue/button'
import ButtonMenu from '@/assets/icons/Buttons/ButtonMenu.vue'
import { useDishes } from '@/composables/useDishes.js'
import { ref, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n/index.js'
import Menu from 'primevue/menu'
import HeartEmpty from '@/assets/icons/2States/HeartEmpty.vue'
import HeartFull from '@/assets/icons/2States/HeartFull.vue'
import { usePlanningStore } from '@/stores/planningStore'
import AddDishToPlanningDialog from '../Planning/AddDishToPlanningDialog.vue'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const props = defineProps<{
  dish: Dish
}>()

const emit = defineEmits<{
  (event: 'handleDeletion', wasDeleted: boolean): void
  (event: 'handleDuplication', duplicationResponse: null | Dish): void
  (event: 'handleFavoriteChange', favoriteResponse: boolean | null): void
  (event: 'openPlanDishDialog', dish: Dish): void
  (event: 'cardClicked'): void
}>()

const { deleteDish, duplicateDish, toggleFavorite } = useDishes()

const showAddToPlanningDialog: Ref<boolean> = ref(false)

const planningStore = usePlanningStore()
const popupMenu = ref()
const popUpMenuItems = ref([
  {
    items: [
      {
        label: t('menuDishView.topMenu.addtoWeekMenu'),
        icon: 'pi pi-calendar',
        command: () =>
          addToWeekMenu()
      },
      {
        label: t('menuDishView.topMenu.duplicate'),
        icon: 'pi pi-clone',
        command: () =>
          duplicateDish(props.dish.id, 'copy').then((duplicationResponse) =>
            emit('handleDuplication', duplicationResponse)
          ),
      },
      {
        label: t('menuDishView.topMenu.delete'),
        icon: 'pi pi-trash',
        command: () =>
          deleteDish(props.dish.id).then((wasDeleted) => emit('handleDeletion', wasDeleted)),
      },
      // {
      //   label: t('menuDishView.topMenu.share'),
      //   icon: 'pi pi-share-alt',
      // },
    ],
  },
])

async function addToWeekMenu() {
  if (!props.dish) return

  planningStore.dishToPlan = {
    clipboardDish: props.dish,
    selectedPlannedMenuIds: [],
    dishType: 'main'
  };

  showAddToPlanningDialog.value = true;
}


function togglePopUpMenu(event: MouseEvent) {
  popupMenu.value.toggle(event)
}
</script>

<template>
  <Menu ref="popupMenu" :model="popUpMenuItems" :popup="true" :pt="{ submenuHeader: { class: '!p-0' } }"
    :pt-options="{ mergeProps: true }" />
  <BaseCard class="p-3 mb-4" @click="$emit('cardClicked')">
    <div class="flex flex-row items-start justify-between gap-4">
      <div>
        <h3 class="line-clamp-2">{{ dish.name }}</h3>
        <p class="text-sm italic">
          <!-- {{ dish.created_using }} -->
          {{ t(`history.${dish.created_using}GeneratedDish`) }}
        </p>
      </div>
      <div v-if="!planningStore.choosingDishToPlan" class="flex flex-row gap-2" @click.stop>
        <Button link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }" :pt-options="{ mergeProps: true }" @click="
          toggleFavorite(props.dish.id).then((favoriteResponse) =>
            emit('handleFavoriteChange', favoriteResponse)
          )
          ">
          <template #icon>
            <HeartFull v-if="dish.is_favorite" />
            <HeartEmpty v-else />
          </template>
        </Button>
        <Button link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8 rotate-90' } }" :pt-options="{ mergeProps: true }"
          @click="togglePopUpMenu">
          <template #icon>
            <ButtonMenu />
          </template>
        </Button>
      </div>
    </div>

    <ul v-if="dish.isPlanned" class="flex flex-col">
      <li>
        <h4 class="inline text-accent">Planifié le :</h4>
      </li>
      <li v-for="(plannedDish, index) in dish.isPlanned" :key="index" class="flex flex-row gap-2 text-sm">
        <p class="truncate">{{ new Date(plannedDish.date).toLocaleString('fr-CH', {
          dateStyle: 'short',
          }) }} - {{ plannedDish.menu_name }}</p>
      </li>
    </ul>
    <ul class="flex flex-col mb-6">
      <li>
        <h4 class="inline">Date de création</h4>
        {{
        new Date(dish.created_at).toLocaleString('fr-CH', {
        dateStyle: 'short',
        })
        }}
      </li>
      <!-- <li>
        <h4 class="block mb-1">Ingrédients</h4>
        <IngredientsList
        gradientFrom="white"
        list-height="full"
        :ingredients="dish.ingredients"
        />
      </li> -->
    </ul>
    <div class="flex flex-row items-center justify-between">
      <div class="flex flex-row w-24 h-6 gap-3 text-primary-950">
        <ChefHatFull v-for="i in dish.difficulty" :key="i" />
        <ChefHatEmpty v-for="i in 4 - dish.difficulty" :key="i" />
      </div>
      <div class="px-3 py-1 text-sm text-white rounded-full bg-primary-500">
        <div>~ CHF {{ calculateDishPrice(dish) }} / pers.</div>
      </div>
    </div>
  </BaseCard>
  <AddDishToPlanningDialog v-model:visible="showAddToPlanningDialog" :dish="dish"
    @close-sidebar="showAddToPlanningDialog = false" />
</template>

<style scoped></style>
