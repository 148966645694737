import DishCreationFormView from '@/views/Profile/DishCreationFormView.vue'
import FavoritesView from '@/views/MyRecipes/FavoritesView.vue'
import GenerationHistoryView from '@/views/MyRecipes/GenerationHistoryView.vue'
import ProfileHomeView from '@/views/Profile/ProfileHomeView.vue'
import MyScansView from '@/views/MyRecipes/MyScansView.vue'
import MyProfileView from '@/views/Profile/MyProfileView.vue'
import SuppliersView from '@/views/Profile/SuppliersView.vue'

const profileRoutes = [
  {
    path: '/profile',
    name: 'profile',
    meta: {
      headerConfig: {
        headerLabel: 'profile',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: MyProfileView,
  },
  // {
  //   path: '/profile/favorites',
  //   name: 'favorites',
  //   meta: {
  //     headerConfig: {
  //       headerLabel: 'myFavorites',
  //       hide: false,
  //       showBack: true,
  //       showProfile: false,
  //     },
  //     footerConfig: {
  //       hide: true,
  //     },
  //     securityConfig: {
  //       requireAuthentication: true,
  //       rolesAllowed: ['common', 'admin'],
  //     },
  //     containerMargins: true,
  //   },
  //   component: FavoritesView,
  // },
  {
    path: '/profile/add-edit-recipe',
    name: 'dish-creation-edition-form',
    meta: {
      headerConfig: {
        headerLabel: 'profile',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    props: (route) => ({ dishEdition: route.query.edit === 'true' ? true : false }),
    component: DishCreationFormView,
  },
  // {
  //   path: '/profile/generation-history',
  //   name: 'generation-history',
  //   meta: {
  //     headerConfig: {
  //       headerLabel: 'history',
  //       hide: false,
  //       showBack: true,
  //       showProfile: false,
  //     },
  //     footerConfig: {
  //       hide: true,
  //     },
  //     securityConfig: {
  //       requireAuthentication: true,
  //       rolesAllowed: ['common', 'admin'],
  //     },
  //     containerMargins: true,
  //   },
  //   component: GenerationHistoryView,
  // },
  // {
  //   path: '/profile/my-scans',
  //   name: 'my-scans',
  //   meta: {
  //     headerConfig: {
  //       headerLabel: 'myScans',
  //       hide: false,
  //       showBack: true,
  //       showProfile: false,
  //     },
  //     footerConfig: {
  //       hide: true,
  //     },
  //     securityConfig: {
  //       requireAuthentication: true,
  //       rolesAllowed: ['common', 'admin'],
  //     },
  //   },
  //   component: MyScansView,
  // },
  {
    path: '/profile/my-profile',
    name: 'my-profile',
    meta: {
      headerConfig: {
        headerLabel: 'myProfile',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: MyProfileView,
  },
  {
    path: '/profile/suppliers',
    name: 'suppliers',
    meta: {
      headerConfig: {
        headerLabel: 'suppliers',
        hide: false,
        showBack: true,
        showProfile: false,
      },
      footerConfig: {
        hide: true,
      },
      securityConfig: {
        requireAuthentication: true,
        rolesAllowed: ['common', 'admin'],
      },
      containerMargins: true,
    },
    component: SuppliersView,
  },
]

export default profileRoutes
