<script setup lang="ts">
import { useAuth } from '@/composables/useAuth';
import { useCategories } from '@/composables/useCategories';
import { useFetch } from '@/composables/useFetch';
import type { MessageSchema } from '@/i18n';
import { usePlanningStore } from '@/stores/planningStore';
import { type Dish, type Ingredient } from '@/types/dish';
import Sidebar from 'primevue/sidebar';
import { computed, onMounted, onUpdated, ref, watch, watchEffect, type ModelRef, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import type { MenuCourse } from '@/types/planning';
import MenuDishView from '@/views/Menus/MenuDishView.vue';
import { useDishesStore } from '@/stores/dishesStore';
import DishImage from '../Menus/DishImage.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { calculateDishPrice } from '@/utils/prices';
import { transformAndSortIngredients } from '@/utils/dishes';
import HeartFull from '@/assets/icons/2States/HeartFull.vue';
import HeartEmpty from '@/assets/icons/2States/HeartEmpty.vue';
import { useEnvStore } from '@/stores/envStore';
import Button from 'primevue/button';
import { useDishes } from '@/composables/useDishes';
import { usePlanning } from '@/composables/usePlanning';

const { t, te } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

// const { categories } = useCategories();
const showSideBar: ModelRef<boolean | undefined> = defineModel('visible', { required: true })
const sideBarIngredient: ModelRef<Ingredient | undefined> = defineModel('ingredient');
// const ingredientCategory = { name: t('orders.ingredientsCategories.other'), value: 'other' }
// const dishesStore = useDishesStore();
// const ingredientUnits = ref(dishesStore.units);
const { isFetching, fetchData } = useFetch()

const router = useRouter();
const toast = useToast();
const user = ref(useAuth().user)
const planningStore = usePlanningStore();
const dishesStore = ref(useDishesStore());
const dish = ref<Dish | null>(null);

const { deleteDish, duplicateDish, toggleFavorite } = useDishes()
const { duplicatePlannedDishAndUpdatePlan  } = usePlanning();

const hasImage = computed(() => {
  return dish.value?.image_url_gastro || dish.value?.image_url_bistro || dish.value?.image_url_user
});

const listIngredients = computed(() => {
  if (!dish.value) return []
  return transformAndSortIngredients(dish.value.ingredients, dish.value.servings)
})


const showAllDates = ref(false);


const displayedPlannedDishes = computed(() => {
  if (showAllDates.value) {
    return dish.value?.isPlanned || [];
  } else {
    return dish.value?.isPlanned?.slice(0, 3) || [];
  }
});

const edition = ref(false);
const dishName = computed(() => {
  return planningStore.dishToPlan?.clipboardDish.name ?? '';
})

const emit = defineEmits<{
  (e: 'closeSidebar'): void
}>()

const props = defineProps<{
  // ingredients: Ingredient[]
  menuCourse: MenuCourse
  plannedMenuName: string
}>()

onUpdated(() => {
  edition.value = sideBarIngredient.value?.id === -1 ? false : true;
  console.log(dish.value)
})

onMounted(async () => {
  if (props.menuCourse?.dish?.id) {
    const dishId = props.menuCourse.dish.id;
    const { data, error } = await fetchData(`${useEnvStore().apiUrl}/dishes/${dishId}`);

    if (error) {
      console.error('Failed to fetch dish data:', error);
    } else {
      // Update the dish with the fetched data
      // dishesStore.value.selectedDish = data as Dish;
      dish.value = data as Dish;
    }
  }
});

const redirectToDishDetail = () => {
  if (!props.menuCourse?.dish) return;
  const dishId = props.menuCourse?.dish.id; // Assuming dish has an id property
  router.push(`/menu/dishes/${dishId}`);
}

async function openNewDishInstance () {
  if (!dish.value) return;
  const duplicateDish = await duplicatePlannedDishAndUpdatePlan(dish.value, props.menuCourse.id, props.menuCourse.planned_menu_id)
  if (duplicateDish) {
    router.push(`/menu/dishes/${duplicateDish.id}`);
   }
}

</script>
<template>
  <Sidebar v-model:visible="showSideBar" v-if="dish"
    :header="t('menuDishView.addDishtoWeekMenu', { dishName: dishName })" position="bottom" style="height: 90%"
    :block-scroll="true" :pt="{
      content: { class: 'px-0 pb-6 mt-6 mb-8 h-full w-full grow overflow-y-auto' }
    }">
    <template #header>
      <h2 class="w-full mb-0">{{ dish.name }}</h2>
    </template>
    <div class="mx-8 mb-8">
        <!-- <div class="flex justify-between gap-6 items-top">
          <h3>
            {{ dish ? dish.name : t('common.loading') }}
          </h3>
          <Button v-if="dish" link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }"
            :pt-options="{ mergeProps: true }">
            <template #icon>
              <HeartFull v-if="dish.is_favorite" />
              <HeartEmpty v-else />
            </template>
          </Button>
        </div> -->
      <div v-if="dish && hasImage" class="flex justify-center my-5">
        <DishImage :dishId="dish.id" :type="dish.selected_image" :alt="dish.name" />
      </div>
      <div v-if="dish && dish.isPlanned" class="mb-6">
        <h3 class="mb-2">Planifié le :</h3>
        <ul class="flex flex-col gap-3">
          <li v-for="(plannedDish, index) in displayedPlannedDishes" :key="index" class="cursor-pointer">
            {{ new Date(plannedDish.date).toLocaleString('fr-CH', {
            dateStyle: 'short',
            }) }} - {{ plannedDish.menu_name }}
          </li>
        </ul>
        <div class="mt-3">
          <p v-if="!showAllDates && dish.isPlanned.length > 3" @click="showAllDates = true" class="mb-10 inline-button">
            {{ t('menuDishView.planning.showMoreDates', {
            count: dish.isPlanned.length - 3, s: dish.isPlanned.length - 3
            > 1 ? 's' : ''
            }) }}
          </p>
          <p v-if="showAllDates" @click="showAllDates = false" class="mb-10 inline-button">
            {{ t('menuDishView.planning.showLessDates') }}
          </p>
        </div>
      </div>
      <div class="flex flex-col gap-3">
        <p><span class="font-bold">{{ t('profileFavorites.creationForm.numberOfPeople') }}: </span>{{ dish?.servings }}
        </p>
        <DataTable :value="listIngredients" size="small">
          <Column field="quantity" :header="t('profileFavorites.creationForm.quantityShort')">
            <template #body="slotProps">
              {{ slotProps.data.quantity > 0 ? slotProps.data.quantity : '' }}
            </template>
          </Column>
          <Column field="unit" :header="t('profileFavorites.creationForm.unit')" />
          <Column field="name" :header="t('profileFavorites.creationForm.name')" />
          <Column field="price" :header="t('profileFavorites.creationForm.price')" />
        </DataTable>
        <div class="flex flex-col items-end gap-4">
          <!-- <p class="font-bold">~ CHF {{ dish ? calculateDishPrice(dish, numberOfPeople) : 0 }}</p> -->
          <div class="flex justify-between w-full gap-4">
            <span>~ CHF {{ dish ? calculateDishPrice(dish, 1) : 0 }} /
              {{ t('menuDishView.nbPersonShort') }}</span>
            <span class="font-bold">~ CHF {{ dish ? calculateDishPrice(dish, dish.servings) : 0 }}</span>
          </div>
        </div>
      </div>

    </div>
    <div class="fixed inset-x-0 bottom-0 z-50 px-4 py-3 bg-white custom-shadow flex flex-row justify-between gap-6">
      <Button label="Modifier pour cette date" @click="openNewDishInstance"
        :disabled="planningStore.dishToPlan?.selectedPlannedMenuIds.length === 0" outlined/>
      <Button label="Modifier pour toutes les dates" @click="redirectToDishDetail" 
        :disabled="planningStore.dishToPlan?.selectedPlannedMenuIds.length === 0" />
    </div>
  </Sidebar>
</template>